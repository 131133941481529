html,
body,
#root {
  height: 100%;
}

.MuiContainer-root {
  padding-top: 20px;
  padding-bottom: 50px;
}

.coverages-table {
  table {
    tbody {
      tr {
        background-color: #ddd;
      }
      tr:nth-child(odd) {
        background-color: #eee;
      }
    }
  }
}
